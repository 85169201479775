<template>
  <div>
    <el-container>
      <el-header>
        <el-row>
          <el-col :span="0.5">账套:</el-col>
          <el-col :span="2"><el-input v-model="params.remark"  size="mini"></el-input></el-col>
          <el-col :span="2"><el-button @click="loaddata" size='mini'>账套查询</el-button></el-col>
          <el-col :span="0.5">编码:</el-col>
          <el-col :span="2"><el-input v-model="params.details.codes" size="mini"></el-input></el-col>
          <el-col :span="0.5">~</el-col>
          <el-col :span="2"><el-input v-model="params.details.codee" size="mini"></el-input></el-col>
          <el-col :span="1"></el-input></el-col>
          <el-col :span="1" style="text-align:right">名称:</el-col>
          <el-col :span="2"><el-input v-model="params.details.name" size="mini"></el-input></el-col>
          <el-col :span="1"><el-button @click="selectPartner" size="mini">查询</el-button></el-col>
          <el-col :span="1"><el-button @click="syc" size="mini">同步</el-button></el-col>
          <!-- <el-col :span="2"><el-button @click="sycall">全部同步</el-button></el-col> -->
        </el-row>
      </el-header>
      <el-container>
        <div style="width: 520px">
          <el-table :data="accounts" @selection-change="handleSelectionChange" height="600">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="acc2" label="数据库"></el-table-column>
            <el-table-column prop="accountName" label="套账"></el-table-column>
          </el-table>
        </div>
        <div style="width: 820px">
          
          <el-table :data="partners" @selection-change="handleSelectionChange2" height="600">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="code" label="往来单位编码"></el-table-column>
            <el-table-column prop="name" label="往来单位名称"></el-table-column>
            <el-table-column prop="codename" label="分类编码"></el-table-column>
          </el-table>
        
          <el-pagination
            background
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            :page-size="params.page.size"
            :total="params.page.total">
          </el-pagination>
        </div>
        <el-main></el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import ax from "@/js/ax.js";
export default {
  name: "account",
  extends: ax.control(),
  data() {
    return {
      accounts: [],
      partners: [],
      multipleSelection: [],
      multipleSelection1: [],
      height: document.documentElement.clientHeight,
      currentPage4:4,
      params:{
        details:{
          codes:"",
          codee:"",
          name:""
        },
        page:{
          size:500,
          current:1,
          total:0
        }
      }
    };
  },
  mounted() {
    this.loaddata();
  },
  methods: {
    handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        this.params.page.current = val;
        this.selectPartner();
      },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSelectionChange2(val) {
      this.multipleSelection1 = val;
    },
    sycall(){

    },
    syc() {
      let _this = this;
      ax.base
        .post("/syc/sycpartner", {
          details: this.multipleSelection,
          classes: this.multipleSelection1,
        })
        .then((data) => {
          _this.$message({ type: "success", message: data.data });
        })
        .catch((data) => {
          _this.$message({ type: "error", message: data.data });
        });
    },
    selectPartner(){
      let _this = this
      if(_this.params.details.codes==''){
        _this.params.details.codes=''
      }
      if(_this.params.details.codee==''){
        _this.params.details.codee='zzzzzzzzz'
      }
       ax.base.post("/syc/selectPartner", _this.params)
        .then((data) => {
           console.log(data)
          _this.partners = data.data.records;
          _this.params.page.total = data.data.total;
        })
        .catch((data) => {
        });
    },
    loaddata() {
      let _this = this;

      ax.base
        .post("/syc/getDetail", { type: "partnerclass",remark:_this.params.remark })
        .then((data) => {
          _this.accounts = data.data;
        })
        .catch((data) => {
        });
        this.selectPartner();
      return;
      ax.base.post("/syc/selectPartner", null)
        .then((data) => {
          _this.partners = data.data;
        })
        .catch((data) => {
        });
    },
  },
};
</script>
<style scoped>
.el-header,
.el-footer {
  background-color: #b3c0d1;
  color: #333;
  /* text-align: center; */
  line-height: 60px;
}

.el-main {
  background-color: #e9eef3;
  color: #333;
  /* text-align: center; */
  line-height: 600px;
}

body > .el-container {
  margin-bottom: 40px;
}



.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
</style>